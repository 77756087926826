import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { page } from "components/page";
import Login from "components/login/Login";
import axios from "axios";

@withI18next(["common"])
@page
class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    let { search } = this.props;
    let params = { ...search };
    let { lang } = params;
    if (lang === "cht") {
      this.handleChangeLanguage("zh");
    } else if (lang === "eng") {
      this.handleChangeLanguage("en");
    }
  }

  handleChangeLanguage(lng) {
    axios
      .get("/api/jumperrwdWs/setSession?key=locales&value=" + lng)
      .then((res) => {});
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  render() {
    return (
      <Layout {...this.props} isLoginPage={true} hiddenBg={true}>
        <div className="main login_page" id="center">
          <section>
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <Login isLoginPage={true} {...this.props} />
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default LoginPage;
